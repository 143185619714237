import {AbpModule} from "abp-ng2-module";
import {PlatformLocation, registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppAuthService} from '@app/shared/common/auth/app-auth.service';
import {AppConsts} from '@shared/AppConsts';
import {CommonModule} from '@shared/common/common.module';
import {AppSessionService} from '@shared/common/session/app-session.service';
import {AppUiCustomizationService} from '@shared/common/ui/app-ui-customization.service';
import {UrlHelper} from '@shared/helpers/UrlHelper';
import {
    API_BASE_URL,
    ApplicationInfoDto,
    ThemeFooterSettingsDto,
    ThemeHeaderSettingsDto,
    ThemeLayoutSettingsDto,
    ThemeMenuSettingsDto,
    ThemeSettingsDto,
    UiCustomizationSettingsDto
} from '@shared/service-proxies/service-proxies';
import {ServiceProxyModule} from '@shared/service-proxies/service-proxy.module';

import moment from 'moment-timezone';
import {AppPreBootstrap} from './AppPreBootstrap';
import {AppModule} from '@app/app.module';
import {RootRoutingModule} from './root-routing.module';
import {RootComponent} from './root.component';
import {DomHelper} from '@shared/helpers/DomHelper';
import {CookieConsentService} from '@shared/common/session/cookie-consent.service';
import {LocaleMappingService} from '@shared/locale-mapping.service';
import {ToastModule} from "primeng/toast";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DialogModule} from "primeng/dialog";
import {environment} from 'environments/environment';
import {ActivatedRouteSnapshot, ResolveEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";
import {UnsupportedBrowserComponent} from './unsupported-browser/unsupported-browser.component';
import {TranslateService} from "@ngx-translate/core";
import {PrimeNGConfig} from "primeng/api";
import {firstValueFrom} from "rxjs";

// import hljs from 'highlight.js/lib/core';
// import xml from 'highlight.js/lib/languages/xml';
// hljs.registerLanguage('xml', xml);

export function appInitializerFactory(
    injector: Injector,
    platformLocation: PlatformLocation) {
    return () => {
        abp.ui.setBusy();

        return new Promise<boolean>((resolve, reject) => {
            AppConsts.appBaseHref = getBaseHref(platformLocation);
            let appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;

            AppPreBootstrap.run(appBaseUrl, () => {

                handleLogoutRequest(injector.get(AppAuthService));

                if (UrlHelper.isInstallUrl(location.href)) {
                    doConfigurationForInstallPage(injector);
                    abp.ui.clearBusy();
                    resolve(true);
                } else {
                    let appSessionService: AppSessionService = injector.get(AppSessionService);
                    appSessionService.init().then((result) => {
                        initializeAppCssClasses(injector, result);
                        initializeTenantResources(injector);
                        initializeCookieConsent(injector);
                        registerLocales(resolve, reject);
                        // test if the client uses internet explorer, if yes redirect to unsupported page
                        let isIE = /msie\s|trident/i.test(window.navigator.userAgent);
                        if (isIE) {
                            let router = injector.get(Router);
                            abp.ui.clearBusy();
                            return router.navigate(['unsupported']);
                        }
                    }, (err) => {
                        abp.ui.clearBusy();
                        reject(err);
                    });
                }
                let translateService = injector.get(TranslateService);
                let primengConfig = injector.get(PrimeNGConfig);
                translateService.setDefaultLang("de");
                translateService.use(abp.localization.currentLanguage.name);
                translateService.get("primeng").subscribe(res => {
                    primengConfig.setTranslation(res);
                    // Translations resolved
                });
                // init app insights only in prod mode
                if (!environment.production) {
                    return;
                }

                // init app insights
                window['appInsights'] = new ApplicationInsights({
                    config: {
                        instrumentationKey: AppConsts.instrumentationKey
                    }
                });
                window['appInsights'].loadAppInsights();
                let router = injector.get(Router);

                router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
                    const activatedComponent = getActivatedComponent(event.state.root);
                    if (activatedComponent) {
                        logPageView(`${activatedComponent.name} ${getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
                    }
                });
            }, resolve, reject);
        });
    };
}

function logPageView(name?: string, uri?: string) {
    window['appInsights'].trackPageView({name, uri});
}

function getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
        return getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
}

function getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
        path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
        return path + getRouteTemplate(snapshot.firstChild);
    }

    return path;
}

function getDefaultThemeForInstallPage(): UiCustomizationSettingsDto {
    let theme = new UiCustomizationSettingsDto();
    theme.baseSettings = new ThemeSettingsDto();
    theme.baseSettings.theme = 'default';
    theme.baseSettings.menu = new ThemeMenuSettingsDto();

    theme.baseSettings.layout = new ThemeLayoutSettingsDto();
    theme.baseSettings.layout.layoutType = 'fluid';
    theme.baseSettings.header = new ThemeHeaderSettingsDto();
    theme.baseSettings.footer = new ThemeFooterSettingsDto();
    return theme;
}

function setApplicationInfoForInstallPage(injector, theme: UiCustomizationSettingsDto) {
    let appSessionService: AppSessionService = injector.get(AppSessionService);
    appSessionService.theme = theme;
    appSessionService.application = new ApplicationInfoDto();
    appSessionService.application.releaseDate = moment().startOf('day');

}

function doConfigurationForInstallPage(injector) {
    let theme = getDefaultThemeForInstallPage();
    setApplicationInfoForInstallPage(injector, theme);

    initializeAppCssClasses(injector, theme);
}

function initializeAppCssClasses(injector: Injector, theme: UiCustomizationSettingsDto) {
    let appUiCustomizationService = injector.get(AppUiCustomizationService);
    appUiCustomizationService.init(theme);

    //Css classes based on the layout
    if (abp.session.userId) {
        document.body.className = appUiCustomizationService.getAppModuleBodyClass();
    } else {
        document.body.className = appUiCustomizationService.getAccountModuleBodyClass();
    }
}

function initializeTenantResources(injector: Injector) {
    let appSessionService: AppSessionService = injector.get(AppSessionService);

    let metaImage = DomHelper.getElementByAttributeValue('meta', 'property', 'og:image');
    if (metaImage) {
        //set og share image meta tag
        if (!appSessionService.tenant || !appSessionService.tenant.hasLogo) {
            let ui: AppUiCustomizationService = injector.get(AppUiCustomizationService);
            metaImage.setAttribute('content', window.location.origin + '/assets/common/images/app-logo-on-' + abp.setting.get(appSessionService.theme.baseSettings.theme + '.' + 'App.UiManagement.Left.AsideSkin') + '.svg');
        } else {
            metaImage.setAttribute('content', AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo');
        }
    }
}

function initializeCookieConsent(injector: Injector) {
    let cookieConsentService: CookieConsentService = injector.get(CookieConsentService);
    cookieConsentService.init();
}

function getDocumentOrigin() {
    if (!document.location.origin) {
        return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }

    return document.location.origin;
}

function registerLocales(resolve: (value?: boolean | Promise<boolean>) => void, reject: any) {
    if (shouldLoadLocale()) {
        let angularLocale = convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
        import(`../node_modules/@angular/common/locales/${angularLocale}.mjs`)
            .then(module => {
                registerLocaleData(module.default);
                resolve(true);
                abp.ui.clearBusy();
            }, reject);
    }
}

export function shouldLoadLocale(): boolean {
    return abp.localization.currentLanguage.name && abp.localization.currentLanguage.name !== 'en-US';
}

export function convertAbpLocaleToAngularLocale(locale: string): string {
    return new LocaleMappingService().map('angular', locale);
}

export function getRemoteServiceBaseUrl(): string {
    return AppConsts.remoteServiceBaseUrl;
}

export function getCurrentLanguage(): string {
    return abp.localization.currentLanguage.name;
}

export function getBaseHref(platformLocation: PlatformLocation): string {
    let baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
        return baseUrl;
    }

    return '/';
}

function handleLogoutRequest(authService: AppAuthService) {
    let currentUrl = UrlHelper.initialUrl;
    let returnUrl = UrlHelper.getReturnUrl();
    if (currentUrl.indexOf(('account/logout')) >= 0 && returnUrl) {
        authService.logout(true, returnUrl);
    }
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppModule,
        CommonModule.forRoot(),
        AbpModule,
        ServiceProxyModule,
        HttpClientModule,
        RootRoutingModule,
        ToastModule,
        DialogModule,
        ConfirmDialogModule
    ],
    declarations: [
        RootComponent,
        UnsupportedBrowserComponent
    ],
    providers: [
        {provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl},
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [Injector, PlatformLocation],
            multi: true
        },
        {
            provide: LOCALE_ID,
            useFactory: getCurrentLanguage
        }
    ],
    bootstrap: [RootComponent]
})
export class RootModule {

}
