import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbpMultiTenancyService, AbpSessionService } from "abp-ng2-module";
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import {
    _definitions_KPMGFileType,
    ChangeUserLanguageDto,
    InboxFilterDto,
    InboxServiceProxy,
    KnowledgeBoxDtoTreeNode,
    KnowledgeBoxServiceProxy,
    KPMGAppAttachmentsAppServiceProxy,
    ProfileServiceProxy,
    TenantSettingsServiceProxy
} from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { InboxItemsComponent } from '@app/main/kpmg/inboxItems/inboxItems.component';
import { DomSanitizer } from '@angular/platform-browser';

;
import { AppComponentBase } from "@shared/common/app-component-base";
import { delay } from 'rxjs';

@Component({
    templateUrl: './topbar.component.html',
    selector: 'topbar',
    encapsulation: ViewEncapsulation.None
})
export class TopBarComponent extends ThemesLayoutBaseComponent implements OnInit {

    isHost = false;
    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/custom/kpmg-logo.png';
    unreadChatMessageCount = 0;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    chatConnected = false;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
    isNeedHelpEnabled: boolean;
    privacyLink: string;
    legalLink: string;
    needHelpMenuItem: any;
    tenantType: any;
    @ViewChild('inboxModal') inboxModal: InboxItemsComponent;
    knowledgeBoxItems: KnowledgeBoxDtoTreeNode[];
    graduationIconBackground: any;
    newInboxItems: number;

    constructor(
        injector: Injector,
        private _abpSessionService: AbpSessionService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _authService: AppAuthService,
        private _kpmgAttachmentsServiceProxy: KPMGAppAttachmentsAppServiceProxy,
        private _fileDownloadService: FileDownloadService,
        private _knowledgeBoxService: KnowledgeBoxServiceProxy,
        private _inboxServiceProxy: InboxServiceProxy,
    ) {
        super(injector);
        this.graduationIconBackground = this.sanitizer.bypassSecurityTrustStyle('url(' + AppConsts.appBaseUrl + '/assets/common/images/graduation-cap.svg) ');
    }

    ngOnInit() {

        this.tenantType = this.tenantTypeInfo();
        this.isHost = !this._abpSessionService.tenantId;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.languages = _.filter(this.localization.languages, l => (l).isDisabled === false);
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this._knowledgeBoxService.get(true).subscribe(result => {
            this.knowledgeBoxItems = result;
        });
        this.setCurrentLoginInformations();
        this.getMiscAndSupportSettings();

        this.registerToEvents();
        this.getInboxMetadata(false);

        AppComponentBase.messagesHub?.on('addInboxNotification', (userId) => {
            if (userId && abp.session.userId === parseInt(userId)) {
                this.getInboxMetadata(true);
            }
        });
    }

    getInboxMetadata(refresh: boolean) {

        if (refresh) {
            setTimeout(() => {
                this._inboxServiceProxy.getInboxItemMetadata().subscribe((result) => {
                    if (result && result.totalNewItems > 0) {
                        this.newInboxItems = result.totalNewItems;
                    }
                });
            }, 15000);

        } else {
            this._inboxServiceProxy.getInboxItemMetadata().subscribe((result) => {
                if (result) {
                    this.newInboxItems = result.totalNewItems;
                }
            });
        }

    }

    registerToEvents() {
        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });

        abp.event.on('app.onMySettingsModalSaved', () => {
            this.onMySettingsModalSaved();
        });
    }

    getMiscAndSupportSettings() {
        this._profileServiceProxy.getMiscAndSupportSettings().subscribe((setting) => {
            this.isNeedHelpEnabled = setting.supportSettings.isNeedHelpEnabled && !this.isHost;
            this.legalLink = setting.miscSettings.legalLink;
            this.privacyLink = setting.miscSettings.privacyLink;
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath,
                '',
                { secure: location.protocol === 'https:' }
            );

            window.location.reload();
        });
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
    }

    showLoginAttempts(): void {
        abp.event.trigger('app.show.loginAttemptsModal');
    }


    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }

    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }

    changeMySettings(): void {
        abp.event.trigger('app.show.mySettingsModal');
    }

    showChangeLog(): void {
        abp.event.trigger('app.show.ChangeLog');
    }

    showVersionInfo(): void {
        abp.event.trigger('app.show.VersionInfo');
    }

    showOpenSourceComponents(): void {
        abp.event.trigger('app.show.OpenSourceComponents');
    }

    showSupportMailModal(): void {
        abp.event.trigger('app.show.showSupportMailModal');
    }

    logout(): void {
        this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    downloadDisclaimer() {
        this._kpmgAttachmentsServiceProxy
            .getFile(_definitions_KPMGFileType.Disclaimer)
            .subscribe((result) => {
                if (result.fileToken) {
                    this._fileDownloadService.downloadTempFile(result);
                } else {
                    abp.message.error(this.l('NoFileAvailable'));
                }

            });
    }

    downloadChangelog() {
        this._kpmgAttachmentsServiceProxy
            .getFile(_definitions_KPMGFileType.Changelog)
            .subscribe((result) => {
                if (result.fileToken) {
                    this._fileDownloadService.downloadTempFile(result);
                } else {
                    abp.message.error(this.l('NoFileAvailable'));
                }

            });
    }

    downloadManual() {
        this._kpmgAttachmentsServiceProxy
            .getFile(_definitions_KPMGFileType.Manual)
            .subscribe((result) => {
                if (result.fileToken) {
                    this._fileDownloadService.downloadTempFile(result);
                } else {
                    abp.message.error(this.l('NoFileAvailable'));
                }

            });
    }

    goToMyInbox() {
        this.inboxModal.show();
    }

    getEnabledChildren(item: KnowledgeBoxDtoTreeNode): KnowledgeBoxDtoTreeNode[] {

        if (item && item.children) {
            return item.children.filter(x => x.data.isEnabled);
        } else {
            return [];
        }
    }

    downloadFile(file: KnowledgeBoxDtoTreeNode) {
        if (file.data.storageFileId) {
            console.log(file.data);
            this._fileDownloadService.downloadKnowledgeBoxForTopbar(file.data.id, file.data.hostItemId, file.data.attachment.name)
                .subscribe();
        }
    }

}
