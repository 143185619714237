export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export enum FileUploadType {
    KnowledgeBox = "knowledgebox",
    SupportMail = "supportmail",
    Calculation = "calculation",
    ChangeDeclarationStatus = "changedeclarationstatus",
    Declaration = "declaration",
    LegalRemedy = "legalremedy",
    OtherMessage = "othermessage",
    TenantLogo = "tenantlogo",
    Building = "building",
    Company = "company",
    EconomicUnit = "economicunit",
    ImportTemplate = "importtemplate",
    Land = "land",
    NotePropertyTax = "notepropertytax",
    NoteRateableValue = "noterateablevalue",
    OperatingEquipment = "operatingequipment",
    PdfTemplate = "pdftemplate",
    Rentee = "rentee",
}
