import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
    _definitions_ImportTemplateType,
    FileDto,
    FileServiceProxy
} from '@shared/service-proxies/service-proxies';
import { HttpClient } from "@angular/common/http";
import { ModelUploadHelper } from "@shared/helpers/ModelUploadHelper";
import { Base64Helper } from "@shared/helpers/Base64Helper";
import { catchError, Observable, of, switchMap, take } from "rxjs";
import { LocalizePipe } from "@shared/common/pipes/localize.pipe";

@Injectable()
export class FileDownloadService {

    constructor(
        private readonly client: HttpClient,
        private readonly localize: LocalizePipe,
        private readonly modelUploadHelper: ModelUploadHelper,
        private readonly fileService: FileServiceProxy
    ) {
    }

    downloadTempFile(file: FileDto) {
        const formData: FormData = new FormData();
        this.modelUploadHelper.sanitize(file);
        const uploadFile = new Blob([Base64Helper.b64EncodeUnicode(JSON.stringify(file))]);
        formData.append('model', uploadFile, 'model.txt');

        this.client.post(AppConsts.remoteServiceBaseUrl + '/api/File/DownloadTempFile', formData, {
            observe: "response",
            responseType: "blob"
        }).subscribe(res => {
            this.downloadBlob(res.body, file.fileName);
        });
    }

    downloadLegalRemedyProtocolAttachment(id: any, companyId: any, filename: string) {
        return this.fileService.downloadLegalRemedyProtocolAttachment(id, companyId)
            .pipe(
                switchMap(res => {
                    this.downloadBlob(res.data, filename);
                    return of(true);
                }),
                catchError((err, caught) => {
                    abp.message.error(this.localize.transform('NoFileAvailable'));
                    return of(true);
                }));
    }
    downloadOtherMessageProtocolAttachment(id: any, companyId: any, filename: string) {
        return this.fileService.downloadOtherMessageProtocolAttachment(id, companyId)
            .pipe(
                switchMap(res => {
                    this.downloadBlob(res.data, filename);
                    return of(true);
                }),
                catchError((err, caught) => {
                    abp.message.error(this.localize.transform('NoFileAvailable'));
                    return of(true);
                }));
    }

    downloadTaxDeclarationProtocolAttachment(id: number, companyId: number, filename: string) {
        return this.fileService.downloadTaxDeclarationProtocolAttachment(id, companyId)
            .pipe(
                switchMap(res => {
                    this.downloadBlob(res.data, filename);
                    return of(true);
                }),
                catchError((err, caught) => {
                    abp.message.error(this.localize.transform('NoFileAvailable'));
                    return of(true);
                }));
    }

    downloadFromInbox(inboxItemId: number, filename: string): Observable<boolean> {
        return this.fileService.downloadFromInbox(inboxItemId)
            .pipe(
                switchMap(res => {
                    this.downloadBlob(res.data, filename);
                    return of(true);
                }),
                catchError((err, caught) => {
                    abp.message.error(this.localize.transform('NoFileAvailable'));
                    return of(true);
                }));
    }

    downloadAllFromInbox(archive: string): Observable<boolean> {
        return this.fileService.downloadAllFromInbox(archive)
            .pipe(
                switchMap(res => {
                    this.downloadBlob(res.data, 'Inbox.zip');
                    return of(true);
                }),
                catchError((err, caught) => {
                    abp.message.error(this.localize.transform('NoFileAvailable'));
                    return of(true);
                }));
    }

    downloadLoginManual(): Observable<boolean> {
        return this.fileService.downloadLoginGuidelineFile().pipe(
            switchMap(res => {
                this.downloadBlob(res.data, res.fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadImportTemplate(templateType: _definitions_ImportTemplateType): Observable<boolean> {
        return this.fileService.downloadImportTemplate(templateType).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, res.fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadImportTemplateById(id: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadImportTemplateById(id).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadKnowledgeBox(storageFileId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadKnowledgeBox(storageFileId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }
    downloadOtherMessageCommentAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadOtherMessageCommentAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadLegalRemedyCommentAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadLegalRemedyCommentAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadDeclarationCommentAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadDeclarationCommentAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadBuildingAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadBuildingAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadCompanyAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadCompanyAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadEconomicUnitAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadEconomicUnitAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadLandAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadLandAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadNotePropertyTaxAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadNotePropertyTaxAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadNoteRateableValueAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadNoteRateableValueAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadOperatingEquipmentAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadOperatingEquipmentAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadPdfTemplate(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadPdfTemplate(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadRenteeAttachment(attachmentId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadRenteeAttachment(attachmentId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadDeclarationPdf(declarationId: number, revision: number, companyId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadDeclarationPdf(declarationId, revision, companyId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadOtherMessagePdf(otherMessageId: number, revision: number, companyId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadOtherMessagePdf(otherMessageId, revision, companyId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadLegalRemedyPdf(legalRemedyId: number, revision: number, companyId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadLegalRemedyPdf(legalRemedyId, revision, companyId).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadKnowledgeBoxForTopbar(knowledgeBoxId: number, hostKnowledgeBoxId: number, fileName: string): Observable<boolean> {
        return this.fileService.downloadKnowledgeBoxForTopbar(knowledgeBoxId, hostKnowledgeBoxId ?? undefined).pipe(
            switchMap(res => {
                this.downloadBlob(res.data, fileName);
                return of(true);
            }),
            catchError((err, caught) => {
                abp.message.error(this.localize.transform('NoFileAvailable'));
                return of(true);
            }));
    }

    downloadBlob(blob: Blob, fileName: string) {
        //other browsers
        let url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                window.removeEventListener(
                    'click',
                    clickHandler
                );
                abp.ui.clearBusy();
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
    }
}
