import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttachmentFileUploader } from '@app/shared/common/file-upload/attachmentFileUploader';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AttachmentDto } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { FileItem } from 'ng2-file-upload';
import { UploadFileAttachment } from '@shared/utils/classes/UploadFileAttachment';
import { FileUploadType } from "@shared/AppEnums";

@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent extends AppComponentBase implements OnInit {

    // single file
    @Input()
    file: UploadFileAttachment;
    @Output()
    fileChange = new EventEmitter<UploadFileAttachment>();
    @Output()
    onClearFile = new EventEmitter<any>();
    @Input()
    viewOnly: Boolean;
    // multiple files
    @Input()
    multiple: Boolean = false;
    @Input()
    files: UploadFileAttachment[] = [];
    @Output()
    filesChange = new EventEmitter<UploadFileAttachment[]>();
    @Input()
    mimetypes: string[] = abp.custom.attachmentMimeTypes;
    @Input()
    required = false;
    @Input()
    light = false;
    @Input({ required: true })
    fileUploadType: FileUploadType;

    @ViewChild('fileInput') fileInput: ElementRef;

    uploader: AttachmentFileUploader;

    constructor(
        injector: Injector,
        private readonly fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    ngOnInit() {
        this.uploader = new AttachmentFileUploader();
        this.uploader.onAfterAddingFile = (file: FileItem) => {
            if (this.multiple) {
                return file;
            }
            // file is replaced
            if (this.file) {
                this.file.isDeleted = true;
                this.file.storageFileId = undefined;
            } else {
                this.file = new UploadFileAttachment();
            }
            if (file) {
                this.file.name = file.file.name;
                this.file.uploadedFile = file._file;
                this.fileChange.emit(this.file);
            }
        };

        this.uploader.onAfterAddingAll = (files: FileItem[]) => {
            if (!this.multiple) {
                return files;
            }
            if (files) {
                //this.files = this.files.filter(f => f.id !== 0);
                files.every((f, index, array) => {
                    let attachment = new UploadFileAttachment();
                    attachment.name = f.file.name;
                    attachment.fileType = f.file.type;
                    attachment.id = 0;
                    attachment.isDeleted = false;
                    attachment.uploadedFile = f._file;
                    this.files.push(attachment);
                    return true;
                });
                this.filesChange.emit(this.files);
            }
        };
    }

    getFileLabel() {
        // if no file is set and no file uploaded return chosse file label
        if (this.file && this.file.isDeleted && this.uploader.queue.length === 0) {
            return this.l('ChooseFile');
        }

        // if file was added to form, return filename from inputfile
        if (this.uploader.queue.length > 0) {
            return this.uploader.queue[0].file.name;
        }

        // if file is a persistent attachement show file name from persistend attachement
        if (this.file && this.file.storageFileId && !this.file.isDeleted) {
            return this.file.name;
        }

        return this.l('ChooseFile');
    }

    filesReadyForUpload() {
        return this.uploader.queue.length > 0;
    }

    clear() {
        if (this.file) {
            this.file.isDeleted = true;
            this.fileChange.emit(this.file);
        }

        this.files = this.files.filter(f => f.id !== 0);
        this.filesChange.emit(this.files);
        this.uploader.clearQueue();
        // hack to re-enable native input element (otherwise after clear no new file/s could be selected)
        this.fileInput.nativeElement.value = '';
        this.onClearFile.emit();
    }

    deleteAttachment(index: number) {
        if (this.viewOnly) return;
        let file = this.files[index];
        file.isDeleted = true;
        // remove file from array if it has id === 0 (new file), existing files only set deleted flag
        if (file.id === 0) {
            const index = this.files.indexOf(file, 0);
            if (index > -1) {
                this.files.splice(index, 1);
            }
            this.filesChange.emit(this.files);
        }

        // if the current file array is empty, clear the file input also
        if (this.files.length === 0) {
            this.clear();
        }
    }

    downloadAttachment(attachment: AttachmentDto) {
        if (attachment.storageFileId) {
            if (this.fileUploadType === FileUploadType.KnowledgeBox) {
                this.fileDownloadService.downloadKnowledgeBox(attachment.storageFileId, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.Declaration) {
                this.fileDownloadService.downloadDeclarationCommentAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.OtherMessage) {
                this.fileDownloadService.downloadOtherMessageCommentAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.LegalRemedy) {
                this.fileDownloadService.downloadLegalRemedyCommentAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.SupportMail) {
                // can not download files from support mail
            }
            if (this.fileUploadType === FileUploadType.Calculation) {
                // can not download files from calculation
            }
            if (this.fileUploadType === FileUploadType.ChangeDeclarationStatus) {
                // can not download files from change declaration status
            }
            if (this.fileUploadType === FileUploadType.TenantLogo) {
                // can not download files from tenant logo
            }
            if (this.fileUploadType === FileUploadType.Building) {
                this.fileDownloadService.downloadBuildingAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.Company) {
                this.fileDownloadService.downloadCompanyAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.EconomicUnit) {
                this.fileDownloadService.downloadEconomicUnitAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.ImportTemplate) {
                this.fileDownloadService.downloadImportTemplateById(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.Land) {
                this.fileDownloadService.downloadLandAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.NotePropertyTax) {
                this.fileDownloadService.downloadNotePropertyTaxAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.NoteRateableValue) {
                this.fileDownloadService.downloadNoteRateableValueAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.OperatingEquipment) {
                this.fileDownloadService.downloadOperatingEquipmentAttachment(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.PdfTemplate) {
                this.fileDownloadService.downloadPdfTemplate(attachment.id, attachment.name).subscribe();
            }
            if (this.fileUploadType === FileUploadType.Rentee) {
                this.fileDownloadService.downloadRenteeAttachment(attachment.id, attachment.name).subscribe();
            }
        }

    }
}
