import {Component, Injector, ViewChild} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {AppComponentBase} from '@shared/common/app-component-base';
import {ProfileServiceProxy, UserLoginAttemptDto, UserLoginServiceProxy} from '@shared/service-proxies/service-proxies';
import moment from 'moment-timezone';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
    selector: 'loginAttemptsModal',
    templateUrl: './login-attempts-modal.component.html'
})
export class LoginAttemptsModalComponent extends AppComponentBase {

    @ViewChild('loginAttemptsModal') modal: ModalDirective;

    userLoginAttempts: UserLoginAttemptDto[];
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defaultProfilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';

    constructor(
        injector: Injector,
        private _userLoginService: UserLoginServiceProxy
    ) {
        super(injector);
    }

    show(): void {
        this._userLoginService.getRecentUserLoginAttempts().subscribe(result => {
            this.userLoginAttempts = result.items;
            this.modal.show();
        });
    }
    close(): void {
        this.modal.hide();
    }

    getLoginAttemptTime(userLoginAttempt: UserLoginAttemptDto): string {
        return moment(userLoginAttempt.creationTime).fromNow() + ' (' + moment(userLoginAttempt.creationTime).format('YYYY-MM-DD hh:mm:ss') + ')';
    }
}
