<div #createOrEditModal="bs-modal" (onShown)="onShown()" [config]="{backdrop: 'static' }" aria-hidden="true"
    aria-labelledby="createOrEditModal" bsModal class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #supportEmailForm="ngForm" (ngSubmit)="sendEmail()" autocomplete="off" novalidate>
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{"SupportMail.Title" | localize}}</span>
                    </h4>
                    <button (click)="close()" aria-label="Close" class="close" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body overflow-y-auto" style="max-height: 75vh;" id="supportMailBody">
                    <p-tabView>
                        <p-tabPanel>
                            <ng-template pTemplate="header">{{"SupportMail.Adresses" | localize}}</ng-template>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="Subject">
                                    {{"SupportMail.Subject" | localize}}
                                    *
                                </label>
                                <div class="col-9">
                                    <input #mailSubjectInput="ngModel" [(ngModel)]="mail.subject"
                                        class="form-control m-input" id="Subject" name="Subject" required type="text" />
                                    <validation-messages [formCtrl]="mailSubjectInput"></validation-messages>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-3 col-form-label" for="Receiver">{{"SupportMail.Receiver" |
                                    localize}}</label>
                                <div class="col-9">
                                    <input [(ngModel)]="mail.receiver" class="form-control m-input" disabled
                                        id="Receiver" name="Receiver" required type="text" />
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-3 col-form-label" for="MailboxCC">{{"SupportMail.CC" |
                                    localize}}</label>
                                <div class="col-9">
                                    <p-chips #chipComponentCC (onAdd)="onAddMailbox($event, chipComponentCC)"
                                        [addOnBlur]="true" [addOnTab]="true" [allowDuplicate]="false" separator=","
                                        [styleClass]="chipComponentCC.length > 0 ? '' : 'ng-invalid'">
                                    </p-chips>
                                    <input #chipComponentCCInput="ngModel" [(ngModel)]="mail.cCs" class="form-control"
                                        id="MailboxCC" name="MailboxCC" type="hidden">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-3 col-form-label" for="MailboxBCC">{{"SupportMail.BCC" |
                                    localize}}</label>
                                <div class="col-9">
                                    <p-chips #chipComponentBCC="ngModel" (onAdd)="onAddMailbox($event, chipComponentBCC)"
                                        [(ngModel)]="emailBCCs" [addOnBlur]="true" [addOnTab]="true"
                                        [allowDuplicate]="false" name="MailboxBCC" separator=",">
                                    </p-chips>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">{{"SupportMail.Content" | localize}}</ng-template>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="Tenant">{{"SupportMail.Body.Tenant" |
                                    localize}} *</label>
                                <div class="col-3">
                                    <input [(ngModel)]="mail.body.tenant" class="form-control m-input" id="Tenant"
                                        name="Tenant" readonly required type="text" />
                                </div>
                                <label class="col-3 col-form-label" for="Category">{{"SupportMail.Body.Category" |
                                    localize}} *</label>
                                <div class="col-3">
                                    <select #categorySelect="ngModel" [(ngModel)]="mail.body.category"
                                        class="form-control m-input" id="Category" name="Category" required>
                                        <option value="{{l('SupportMail.Body.Category.Request')}}">
                                            {{l('SupportMail.Body.Category.Request')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.Category.Error')}}">
                                            {{l('SupportMail.Body.Category.Error')}}
                                        </option>
                                    </select>
                                    <validation-messages [formCtrl]="categorySelect"></validation-messages>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="Date">
                                    {{"SupportMail.Body.Date" | localize}}
                                    *
                                </label>
                                <div class="col-3">
                                    <p-calendar #mailDateInput="ngModel"
                                        (ngModelChange)="this.mail.body.date = getMoment($event)"
                                        [ngModel]="this.mail.body.date | m2d" id="Date" inputStyleClass="form-control"
                                        name="Date" required useUtc></p-calendar>
                                    <validation-messages [formCtrl]="mailDateInput"></validation-messages>
                                </div>
                                <label class="col-3 col-form-label" for="Time">{{"SupportMail.Body.Time" |
                                    localize}}</label>
                                <div class="col-3">
                                    <input [(ngModel)]="mail.body.time" class="form-control m-input" id="Time"
                                        max="24:00" min="00:00" name="Time" type="time" />
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="ResponsiblePerson">
                                    {{"SupportMail.Body.ResponsiblePerson" | localize}}
                                    *
                                </label>
                                <div class="col-3">
                                    <input #resposibleInput="ngModel" [(ngModel)]="mail.body.responsiblePerson"
                                        class="form-control m-input" id="ResponsiblePerson" name="ResponsiblePerson"
                                        required type="text" />
                                    <validation-messages [formCtrl]="resposibleInput"></validation-messages>
                                </div>
                                <label class="col-3 col-form-label" for="PhoneNumber">{{"SupportMail.Body.PhoneNumber" |
                                    localize}}</label>
                                <div class="col-3">
                                    <input [(ngModel)]="mail.body.phoneNumber" class="form-control m-input"
                                        id="PhoneNumber" name="PhoneNumber" type="text" />
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="EMail">
                                    {{"SupportMail.Body.Email" | localize}}
                                    *
                                </label>
                                <div class="col-9">
                                    <p-chips #chipComponent3="ngModel" (onAdd)="onAddMailbox($event, chipComponentCC)"
                                        [(ngModel)]="responsibleEmails" [addOnBlur]="true" [addOnTab]="true"
                                        [allowDuplicate]="false" [max]="1" name="EMail" required>
                                    </p-chips>
                                    <validation-messages [formCtrl]="chipComponent3"></validation-messages>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row">
                                <label class="col-3 col-form-label" for="ErrorType">{{"SupportMail.Body.ErrorType" |
                                    localize}} *</label>
                                <div class="col-3">
                                    <select #errorTypeSelect="ngModel" (change)="onErrorTypeChange($event)" [(ngModel)]="mail.body.errorType"
                                        class="form-control m-input" id="ErrorType" name="ErrorType" required >
                                        <option value="{{l('SupportMail.Body.ErrorType.Miscalculation')}}">
                                            {{l('SupportMail.Body.ErrorType.Miscalculation')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorType.Performance')}}">
                                            {{l('SupportMail.Body.ErrorType.Performance')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorType.SystemCrash')}}">
                                            {{l('SupportMail.Body.ErrorType.SystemCrash')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorType.ProcessError')}}">
                                            {{l('SupportMail.Body.ErrorType.ProcessError')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorType.Submision')}}">
                                            {{l('SupportMail.Body.ErrorType.Submision')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorType.Others')}}">
                                            {{l('SupportMail.Body.ErrorType.Others')}}
                                        </option>
                                    </select>
                                    <validation-messages [formCtrl]="errorTypeSelect"></validation-messages>
                                </div>
                                <label class="col-3 col-form-label" for="ErrorCalssification">
                                    {{"SupportMail.Body.ErrorCalssification" | localize}}
                                    *
                                </label>
                                <div class="col-3">
                                    <select #errorCalssificationSelect="ngModel"
                                        [(ngModel)]="mail.body.errorClassification" class="form-control m-input"
                                        id="ErrorCalssification" name="ErrorCalssification" required>
                                        <option value="{{l('SupportMail.Body.ErrorCalssification.Urgent')}}">
                                            {{l('SupportMail.Body.ErrorCalssification.Urgent')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorCalssification.High')}}">
                                            {{l('SupportMail.Body.ErrorCalssification.High')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorCalssification.Medium')}}">
                                            {{l('SupportMail.Body.ErrorCalssification.Medium')}}
                                        </option>
                                        <option value="{{l('SupportMail.Body.ErrorCalssification.Low')}}">
                                            {{l('SupportMail.Body.ErrorCalssification.Low')}}
                                        </option>
                                    </select>
                                    <validation-messages [formCtrl]="errorCalssificationSelect"></validation-messages>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row" *ngIf="shouldShowAdditionalFields">
                                <label class="col-3 col-form-label" for="EconomicUnitType">{{"UnitDisplayName" |
                                    localize}}</label>
                                <div class="col-9">
                                    <div class="input-group">
                                        <input #economicUnitName="ngModel" [(ngModel)]="economicUnitDisplayName" class="form-control" readonly
                                            id="EconomicUnitDisplayName" name="economicUnitDisplayName" pInputText
                                            type="text">
                                        <div class="input-group-append">
                                            <button (click)="openSelectEconomicUnitModal()"
                                                class="btn btn-outline-primary blue picker-modal" type="button">
                                                <i class="fa fa-search"></i> {{l("Pick")}}
                                            </button>
                                        </div>
                                        <div class="input-group-prepend">
                                            <button (click)="setEconomicUnitIdNull()" class="btn btn-outline-danger"
                                                type="button">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <validation-messages [formCtrl]="economicUnitName"></validation-messages>
                                </div>
                                <input [(ngModel)]="mail.body.economicUnitId" class="form-control" hidden
                                    name="mail.body.economicUnitId" type="text">
                            </div>
                            <div class="form-group form-md-line-input row" *ngIf="shouldShowAdditionalFields">
                                <label class="col-3 col-form-label" for="EconomicUnitType">{{"EconomicUnitType" |
                                    localize}}</label>
                                <div class="col-3">
                                    <select #errorTypeSelect="ngModel" [(ngModel)]="mail.body.typeOfEconomicUnit"
                                        class="form-control m-input" id="TypeOfEconomicUnit" name="TypeOfEconomicUnit"
                                        [disabled]="mail.body.economicUnitId != null">
                                        <option value="0">
                                            {{l('RealEstate')}}
                                        </option>
                                        <option value="1">
                                            {{l('AgricultureAndForestry')}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-3 col-form-label" for="FederalState">
                                    {{"FederalState" | localize}}
                                </label>
                                <div class="col-3">
                                    <select #errorTypeSelect="ngModel" [(ngModel)]="mail.body.federalState"
                                        class="form-control m-input" id="FederalState" name="FederalState" [disabled]="mail.body.economicUnitId != null">
                                        <option value="0">
                                            {{l('Enum_FederalState_0')}}
                                        </option>
                                        <option value="1">
                                            {{l('Enum_FederalState_1')}}
                                        </option>
                                        <option value="2">
                                            {{l('Enum_FederalState_2')}}
                                        </option>
                                        <option value="3">
                                            {{l('Enum_FederalState_3')}}
                                        </option>
                                        <option value="4">
                                            {{l('Enum_FederalState_4')}}
                                        </option>
                                        <option value="5">
                                            {{l('Enum_FederalState_5')}}
                                        </option>
                                        <option value="6">
                                            {{l('Enum_FederalState_6')}}
                                        </option>
                                        <option value="7">
                                            {{l('Enum_FederalState_7')}}
                                        </option>
                                        <option value="8">
                                            {{l('Enum_FederalState_8')}}
                                        </option>
                                        <option value="9">
                                            {{l('Enum_FederalState_9')}}
                                        </option>
                                        <option value="00">
                                            {{l('Enum_FederalState_10')}}
                                        </option>
                                        <option value="11">
                                            {{l('Enum_FederalState_11')}}
                                        </option>
                                        <option value="12">
                                            {{l('Enum_FederalState_12')}}
                                        </option>
                                        <option value="13">
                                            {{l('Enum_FederalState_13')}}
                                        </option>
                                        <option value="14">
                                            {{l('Enum_FederalState_14')}}
                                        </option>
                                        <option value="15">
                                            {{l('Enum_FederalState_15')}}
                                        </option>
                                        <option value="16">
                                            {{l('Enum_FederalState_16')}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row" *ngIf="shouldShowAdditionalFields">
                                <label class="col-3 col-form-label" for="EconomicUnitType">{{"Procedure" |
                                    localize}}</label>
                                <div class="col-3">
                                    <select [(ngModel)]="mail.body.typeOfCalculation" class="form-control"
                                        id="CalculationMethod" name="CalculationMethod" [disabled]="mail.body.economicUnitId != null">
                                        <option value ="3">{{l('Unbebaut')}}</option>
                                        <option value ="1">{{l('ErtragswertVerfahren')}}</option>
                                        <option value ="2">{{l('SachwertVerfahren')}}</option>
                                        <option value ="4">{{l('Öffnungsklausel')}}</option>
                                        <option value ="5">{{l('AgricultureAndForestry')}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12">
                                    <label>{{"SupportMail.Body.ErrorDescription" | localize}} *</label>
                                </div>
                            </div>
                            <div class="form-group form-md-line-input row">
                                <div class="col-12">
                                    <textarea #errorDescriptionText="ngModel" [(ngModel)]="mail.body.errorDescription"
                                        class="form-control m-input" cols="100" id="ErrorDescription"
                                        name="ErrorDescription"
                                        placeholder="{{'SupportMail.Body.ErrorDescriptionPlaceHolder' | localize}}"
                                        required rows="5" type="text">
                                    </textarea>
                                    <validation-messages [formCtrl]="errorDescriptionText"></validation-messages>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">{{l("SupportMail.Attachments")}}</ng-template>
                            <br />
                            <div>
                                <label style="font-size: smaller;">{{ l('AttachmentsDateInfo') }}</label>
                            </div>
                            <div class="form-group">
                                <file-upload [(files)]="mail.attachments" [multiple]="true" [fileUploadType]="fileUploadType"></file-upload>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>

                <div class="modal-footer">
                    <button (click)="close()" class="btn btn-primary blue" type="button">{{"Close" | localize}}</button>
                    <button [buttonBusy]="isSendigMail" [disabled]="!supportEmailForm.form.valid"
                        class="btn btn-primary" type="submit">
                        <i class="fa fa-envelope"></i>
                        <span>{{"SupportMail.SendEmail" | localize}}</span>
                    </button>

                </div>

            </form>
        </div>
    </div>
</div>
<economicUnitLookupTableModal #economicUnitLookupTableModal (modalSave)="getNewEconomicUnitId($event)"
    (onViewItem)="onViewEconomicUnitItem.emit($event)">
</economicUnitLookupTableModal>
