import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FeatureCheckerPipe } from "@shared/common/pipes/feature-checker.pipe";
import { LocalizePipe } from "@shared/common/pipes/localize.pipe";
import { PermissionPipe } from "@shared/common/pipes/permission.pipe";
import { DisclaimerComponent } from "@shared/disclaimer/disclaimer.component";
import { InputMaskModule } from "primeng/inputmask";
import { ArrayToTreeConverterService } from "./array-to-tree-converter.service";
import { AutoFocusDirective } from "./auto-focus.directive";
import { BusyIfDirective } from "./busy-if.directive";
import { ButtonBusyDirective } from "./button-busy.directive";
import { FileDownloadService } from "./file-download.service";
import { KpmgFileSelectDirective } from "./file-select.directive";
import { MomentFormatPipe } from "./moment-format.pipe";
import { MomentFromNowPipe } from "./moment-from-now.pipe";
import { NullDefaultValueDirective } from "./null-value.directive";
import { ScriptLoaderService } from "./script-loader.service";
import { StyleLoaderService } from "./style-loader.service";
import { TreeDataHelperService } from "./tree-data-helper.service";
import { ValidationMessagesComponent } from "./validation-messages.component";
import { EqualValidator } from "./validation/equal-validator.directive";
import { PasswordComplexityValidator } from "./validation/password-complexity-validator.directive";
import { DatefilterComponent } from "@shared/datefilter/datefilter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WizardTooltipHelperService } from "@app/shared/services/wizard-tooltip-helper.service";
import { SafePipe } from "@shared/common/pipes/safe.pipe";
import { FileSizePipe } from "@shared/common/pipes/file-size.pipe";
import { MessageBarComponent } from "./custom/messagebar.component";
import { AlertModule } from "ngx-bootstrap/alert";
import { KpmgTableCaptionExtraDirective } from "./kpmg-table-caption-extra.directive";
import { FieldTypeTemplateDirective } from "./field-type-template.directive";
import { TableUtilitiesService } from "./table-utils-service";
import { TableUnsortDirective } from "./TableUnsortDirective";
import { CarouselModule } from "primeng/carousel";
import { InputSwitchModule } from "primeng/inputswitch";
import { TooltipModule as PrimeNgTooltipModule } from "primeng/tooltip";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ReplacePipe } from "@shared/common/pipes/replace.pipe";
import { CustomMaxDirective } from "./validation/custom-max-validator.directive";
import { CustomMinDirective } from "./validation/custom-min-validator.directive";
import { IfNullShowDashPipe } from "@shared/common/pipes/if-null-show-dash";
import { SortByPipe } from "../common/pipes/sort-by.pipe";
import { HttpClient } from "@angular/common/http";
import { AddDaysDatePipe, MomentToDatePipe, StringToMomentPipe } from "@shared/utils/moment-to-date.pipe";
import { UseUtcDirective } from "@shared/utils/use-utc.directive";
import { TranslateLoader, TranslateModule, TranslateStore, } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
    OpenSourceComponentsComponent
} from "@app/shared/layout/open-source-components/open-source-components.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { InputTrimDirective } from "./input-trim.directive";
import { TabViewModule } from "primeng/tabview";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TruncateTextPipe } from "@shared/common/pipes/truncate-text.pipe";
import { PrettyBytesPipe } from "@shared/utils/pretty-bytes.pipe";
import { SpecialCharsDirective } from "./validation/special-chars-validator.directive";
import {LocalizeEnumPipe} from "@shared/common/pipes/localize-enum.pipe";
import {PageReportPipe} from "@shared/common/pipes/page-report.pipe";
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    imports: [
        CommonModule,
        InputMaskModule,
        FormsModule,
        ReactiveFormsModule,
        AlertModule.forRoot(),
        CarouselModule,
        InputSwitchModule,
        PrimeNgTooltipModule,
        OverlayPanelModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ModalModule,
        TabViewModule,
        MatCheckboxModule
    ],
    providers: [
        FileDownloadService,
        ScriptLoaderService,
        StyleLoaderService,
        ArrayToTreeConverterService,
        TreeDataHelperService,
        WizardTooltipHelperService,
        TableUtilitiesService,
        TranslateStore,
        MomentToDatePipe,
        LocalizePipe
    ],
    declarations: [
        DatefilterComponent,
        EqualValidator,
        PasswordComplexityValidator,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        MomentFormatPipe,
        MomentFromNowPipe,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        LocalizePipe,
        LocalizeEnumPipe,
        PageReportPipe,
        PermissionPipe,
        FeatureCheckerPipe,
        DisclaimerComponent,
        KpmgFileSelectDirective,
        SafePipe,
        ReplacePipe,
        FileSizePipe,
        MessageBarComponent,
        KpmgTableCaptionExtraDirective,
        FieldTypeTemplateDirective,
        TableUnsortDirective,
        CustomMaxDirective,
        CustomMinDirective,
        IfNullShowDashPipe,
        SortByPipe,
        MomentToDatePipe,
        StringToMomentPipe,
        PrettyBytesPipe,
        UseUtcDirective,
        OpenSourceComponentsComponent,
        InputTrimDirective,
        TruncateTextPipe,
        AddDaysDatePipe,
        SpecialCharsDirective
    ],
    exports: [
        DatefilterComponent,
        KpmgFileSelectDirective,
        EqualValidator,
        PasswordComplexityValidator,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        MomentFormatPipe,
        MomentFromNowPipe,
        ValidationMessagesComponent,
        NullDefaultValueDirective,
        LocalizePipe,
        LocalizeEnumPipe,
        PageReportPipe,
        PermissionPipe,
        FeatureCheckerPipe,
        DisclaimerComponent,
        SafePipe,
        ReplacePipe,
        FileSizePipe,
        MessageBarComponent,
        KpmgTableCaptionExtraDirective,
        FieldTypeTemplateDirective,
        TableUnsortDirective,
        CustomMaxDirective,
        CustomMinDirective,
        IfNullShowDashPipe,
        SortByPipe,
        MomentToDatePipe,
        StringToMomentPipe,
        PrettyBytesPipe,
        UseUtcDirective,
        OpenSourceComponentsComponent,
        InputTrimDirective,
        TruncateTextPipe,
        AddDaysDatePipe,
        SpecialCharsDirective
    ],
})
export class UtilsModule {
}
