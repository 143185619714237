import { Component, EventEmitter, Injector, OnInit, Output, ViewChild, ViewEncapsulation, } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    CalculationsServiceProxy,
    EconomicUnitLookupTableDto,
    EconomicUnitType,
    ProfileServiceProxy,
    SessionServiceProxy,
    SupportMailBodyDto,
    SupportMailDto,
    _definitions_FederalState,
} from "@shared/service-proxies/service-proxies";
import { FileDownloadService } from "@shared/utils/file-download.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ModelUploadHelper } from "@shared/helpers/ModelUploadHelper";
import { Chips } from "primeng/chips";
import { SelectItem } from "primeng/api";
import { EconomicUnitLookupTableModalComponent } from "@app/main/kpmg/lookups/economicUnit-lookup-table-modal/economicUnit-lookup-table-modal.component";
import moment from "moment";
import { FileUploadType } from "@shared/AppEnums";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: "supportMailModal",
    templateUrl: "./support-mail.component.html",
    styleUrls: ["./support-mail.component.css"],
})
export class SupportMailComponent extends AppComponentBase implements OnInit {
    @ViewChild("createOrEditModal") modal: ModalDirective;
    @ViewChild('chipComponentCC', { static: true }) chipComponentCC: Chips;
    @ViewChild('economicUnitLookupTableModal') economicUnitLookupTableModal: EconomicUnitLookupTableModalComponent;

    @Output() onViewEconomicUnitItem: EventEmitter<any> = new EventEmitter<any>();

    sendSupportMailUrl = AppConsts.remoteServiceBaseUrl + '/SupportMail/Send';
    mail: SupportMailDto;
    emailBCCs: string[];
    responsibleEmails: string[];
    isSendigMail = false;
    chipComponentCC1: string[] = [];
    economicUnitDisplayName = '';
    shouldShowAdditionalFields = false;
    protected fileUploadType = FileUploadType.SupportMail;
    constructor(
        injector: Injector,
        private readonly _profileServiceProxy: ProfileServiceProxy,
        private readonly _sessionService: SessionServiceProxy,
        private readonly _modelUploadHelper: ModelUploadHelper,
        private readonly _calculationsService: CalculationsServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.initMail();
        this.mail.body.economicUnitId = null;
        this.shouldShowAdditionalFields = false;
    }

    show() {
        this.initMail();
        this.modal.show();
    }

    close(): void {
        this.modal.hide();
    }

    initMail() {
        this.isSendigMail = false;
        this.responsibleEmails = [];
        this.emailBCCs = [];
        this.mail = new SupportMailDto();
        this.mail.attachments = [];
        this.mail.body = new SupportMailBodyDto();
        this.mail.cCs = [];
        this._profileServiceProxy.getSupportSettings().subscribe((setting) => {
            this.mail.receiver = setting.supportRecipientsMailbox;
        });
        this._sessionService.getCurrentLoginInformations().subscribe(result => {
            this.mail.body.tenant = result.tenant?.tenancyName;
            this.chipComponentCC1.push(result.user.emailAddress);
        });
    }

    onShown(): void {
        this.chipComponentCC1.forEach(f => {
            this.chipComponentCC.addItem(null, f, false);
        });
    }

    onAddMailbox(event, chipComponent): void {
        if (!AppConsts.emailRegex.test(event.value)) {
            const index = chipComponent.value.indexOf(event.value, 0);
            if (index > -1) {
                chipComponent.value.splice(index, 1);
            }
            abp.notify.warn(this.l('EmailInvalid').replace('{0}', event.value));
        }
    }

    sendEmail() {
        this.isSendigMail = true;
        if (this.responsibleEmails) {
            this.mail.body.eMail = this.responsibleEmails.join(', ');
        }

        if (this.emailBCCs) {
            this.mail.bcCs = this.emailBCCs;
        }
        let formData = new FormData();
        for (const item of this.mail.attachments) {
            if (item['uploadedFile']) {
                formData.append(item['uploadedFile'].name, item['uploadedFile']);
            }
        }
        if(this.chipComponentCC.value){
            this.chipComponentCC.value.forEach(f => {
                this.mail.cCs.push(f);
            });
        }

        this._modelUploadHelper
            .sendModel(this.sendSupportMailUrl, this.mail, formData)
            .subscribe(
                (x) => {
                },
                (err) => {

                    abp.message.error(err.error.error.message);
                },
                () => {

                    abp.notify.success(this.l('EMailSuccessfullySent'));
                    this.close();
                    this.isSendigMail = false;

                }
            );
    }

    openSelectEconomicUnitModal() {
        this.economicUnitLookupTableModal.show(false, this.mail.body.economicUnitId, this.economicUnitDisplayName, moment.utc());
    }

    setEconomicUnitIdNull() {
        this.mail.body.economicUnitId = null;
        this.economicUnitDisplayName = '';
        this.mail.body.federalState = undefined;
        this.mail.body.typeOfEconomicUnit = undefined;
        this.mail.body.typeOfCalculation = undefined;
    }

    getNewEconomicUnitId(eu: EconomicUnitLookupTableDto) {
        this.mail.body.economicUnitId = eu.id;
        this.economicUnitDisplayName = eu.displayName;
        this.mail.body.economicUnitDisplayName = eu.displayName;
        this.mail.body.federalState = eu.federalState;
        this.mail.body.typeOfEconomicUnit = eu.economicUnitType;
        this._calculationsService.getCorrespondingCalculationMethod(eu.id, moment(), false)
            .subscribe(result => {
                this.mail.body.typeOfCalculation = result;
            });

    }

    onErrorTypeChange(event): void {
        if (event.target.value == this.l('SupportMail.Body.ErrorType.Miscalculation')
            || event.target.value == this.l('SupportMail.Body.ErrorType.Submision')
            || event.target.value == this.l('SupportMail.Body.ErrorType.Others')) {
            this.shouldShowAdditionalFields = true;
        } else {
            this.shouldShowAdditionalFields = false;
        }
    }
}
