<div
    #inboxModal="bs-modal"
    [config]="{ backdrop: 'static' }"
    aria-hidden="true"
    aria-labelledby="myLargeModalLabel"
    bsModal
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg" style="max-width: 1200px">
        <div class="modal-content">
            <form *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ "Inbox" | localize }}</span>
                    </h5>
                    <button
                        (click)="close()"
                        [attr.aria-label]="l('Close')"
                        class="close"
                        type="button"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div>
                    <div #content class="">
                        <p-splitter
                            [panelSizes]="[15, 85]"
                            [style]="{ height: '700px' }"
                        >
                            <ng-template pTemplate>
                                <ul *ngIf="inboxItemMetadata" class="mt-3">
                                    <li
                                        class="m-nav__item label_text_primary"
                                        role="none"
                                    >
                                        <a
                                            (click)="shouldShowNewItems(true)"
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                            class="m-nav__link"
                                            href="javascript:"
                                            role="menuitem"
                                            tabindex="-1"
                                        >
                                            <span
                                                class="m-nav__link-title label_text_primary"
                                            >
                                                {{ "New" | localize }} ({{
                                                    inboxItemMetadata.totalNewItems
                                                }})
                                            </span>
                                        </a>
                                    </li>
                                    <li
                                        class="m-nav__item label_text_primary"
                                        role="none"
                                    >
                                        <a
                                            (click)="shouldShowNewItems(false)"
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                            class="m-nav__link"
                                            href="javascript:"
                                            role="menuitem"
                                            tabindex="-1"
                                        >
                                            <span class="m-nav__link-title">
                                                <span class="m-nav__link-wrap">
                                                    <span
                                                        class="m-nav__link-text label_text_primary"
                                                        id="InboxLink"
                                                    >
                                                        {{
                                                            "Archive" | localize
                                                        }}
                                                        ({{
                                                            inboxItemMetadata.totalArchiveItems
                                                        }})
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </ng-template>
                            <ng-template pTemplate>
                                <kpmg-table
                                    #newItemsDT
                                    (editableValue)="updateComment($event)"
                                    (onLazyLoad)="getNewInboxItems($event)"
                                    *ngIf="showNewItems"
                                    [customMenuItems]="customMenuItems"
                                    [menuItems]="newInboxMenuItems"
                                    [styleClass]="'inbox-table'"
                                    [tableConfigType]="
                                        newInboxItemsTableConfigType
                                    "
                                    [totalRecords]="totalNewInboxItemRecords"
                                    [value]="newInboxItems"
                                    class="pl-2 pr-2"
                                    dataKey="id"
                                    this.showNewItems
                                >
                                    <ng-template
                                        fieldType="type"
                                        let-record="record"
                                    >
                                        <span
                                            [ngClass]="
                                                record.downloadDate !==
                                                undefined
                                                    ? 'has-date'
                                                    : 'no-date'
                                            "
                                            >{{
                                                inboxType[record.type]
                                                    | localize
                                            }}
                                        </span>
                                    </ng-template>
                                    <ng-template
                                        fieldType="fileName"
                                        let-record="record"
                                    >
                                        <i
                                            *ngIf="record.fileName"
                                            [ngClass]="setIcon(record.fileName)"
                                            class="cloud mr-2"
                                        ></i>
                                        <span
                                            (click)="
                                                download(
                                                    record.id,
                                                    record.fileName
                                                )
                                            "
                                            [ngClass]="
                                                record.downloadDate !==
                                                undefined
                                                    ? 'has-date'
                                                    : 'no-date'
                                            "
                                            style="cursor: pointer"
                                            >{{ record.fileName }}
                                            <span style="float: right"
                                                >({{
                                                    record.fileSize | fileSize
                                                }})</span
                                            ></span
                                        >
                                    </ng-template>
                                    <ng-template
                                        fieldType="createdOn"
                                        let-record="record"
                                    >
                                        <span
                                            [ngClass]="
                                                record.downloadDate !==
                                                undefined
                                                    ? 'has-date'
                                                    : 'no-date'
                                            "
                                            >{{
                                                record.createdOn
                                                    | date : "d/M/yyyy HH:mm"
                                            }}</span
                                        >
                                    </ng-template>
                                    <ng-template
                                        fieldType="downloadDate"
                                        let-record="record"
                                    >
                                        <span>{{
                                            record.downloadDate
                                                | date : "d/M/yyyy HH:mm"
                                        }}</span>
                                    </ng-template>
                                </kpmg-table>
                                <kpmg-table
                                    #archiveItemsDT
                                    (editableValue)="updateComment($event)"
                                    (onLazyLoad)="getArchiveInboxItems($event)"
                                    *ngIf="!showNewItems"
                                    [customMenuItems]="customMenuItems"
                                    [menuItems]="archiveInboxMenuItems"
                                    [styleClass]="'inbox-table'"
                                    [tableConfigType]="
                                        archiveInboxItemsTableConfigType
                                    "
                                    [totalRecords]="
                                        totalArchiveInboxItemRecords
                                    "
                                    [value]="archiveInboxItems"
                                    class="archive-table pl-2 pr-2"
                                    dataKey="id"
                                >
                                    <ng-template
                                        fieldType="type"
                                        let-record="record"
                                    >
                                        <span
                                            [ngClass]="
                                                record.downloadDate !==
                                                undefined
                                                    ? 'has-date'
                                                    : 'no-date'
                                            "
                                            >{{
                                                inboxType[record.type]
                                                    | localize
                                            }}
                                        </span>
                                    </ng-template>
                                    <ng-template
                                        fieldType="fileName"
                                        let-record="record"
                                    >
                                        <i
                                            *ngIf="record.fileName"
                                            [ngClass]="setIcon(record.fileName)"
                                            class="cloud mr-2"
                                        ></i>
                                        <span
                                            (click)="
                                                download(
                                                    record.id,
                                                    record.fileName
                                                )
                                            "
                                            [ngClass]="
                                                record.downloadDate !==
                                                undefined
                                                    ? 'has-date'
                                                    : 'no-date'
                                            "
                                            style="cursor: pointer"
                                            >{{ record.fileName }}
                                            <span style="float: right"
                                                >({{
                                                    record.fileSize | fileSize
                                                }})</span
                                            ></span
                                        >
                                    </ng-template>
                                    <ng-template
                                        fieldType="createdOn"
                                        let-record="record"
                                    >
                                        <span
                                            [ngClass]="
                                                record.downloadDate !==
                                                undefined
                                                    ? 'has-date'
                                                    : 'no-date'
                                            "
                                            >{{
                                                record.createdOn
                                                    | date : "d/M/yyyy HH:mm"
                                            }}</span
                                        >
                                    </ng-template>
                                    <ng-template
                                        fieldType="archiveDate"
                                        let-record="record"
                                    >
                                        <span>{{
                                            record.archiveDate
                                                | date : "d/M/yyyy HH:mm"
                                        }}</span>
                                    </ng-template>
                                    <ng-template
                                        fieldType="downloadDate"
                                        let-record="record"
                                    >
                                        <span>{{
                                            record.downloadDate
                                                | date : "d/M/yyyy HH:mm"
                                        }}</span>
                                    </ng-template>
                                    <ng-template
                                        fieldType="deletionDate"
                                        let-record="record"
                                    >
                                        <span>{{
                                            record.deletionDate
                                                | date : "d/M/yyyy HH:mm"
                                        }}</span>
                                    </ng-template>
                                </kpmg-table>
                                <div
                                    *ngIf="!showNewItems"
                                    class="warning-label"
                                >
                                    {{ "ArchiveAfter90Days" | localize }}
                                </div>
                                <div class="progress">
                                    <div
                                        class="progress-container label_text_primary"
                                    >
                                        {{ "Capacity" | localize }}
                                        <div
                                            style="
                                                position: absolute;
                                                top: 5px;
                                                left: 50px;
                                                height: 20px;
                                            "
                                        >
                                            <p-progressBar
                                                [showValue]="false"
                                                [style]="{ height: '20px' }"
                                                [value]="capacityPercentage"
                                            ></p-progressBar>
                                        </div>
                                        <div
                                            style="
                                                position: relative;
                                                margin: 0 auto;
                                                text-align: center;
                                            "
                                        >
                                            {{ capacityPercentage }} %
                                        </div>
                                    </div>
                                    <div class="label_text_primary">
                                        {{ currentCapacity }} MB
                                        {{ "OutOf" | localize }}
                                        {{ maxCapacity }}MB
                                    </div>
                                </div>
                                <div
                                    *ngIf="currentCapacity >= maxCapacity"
                                    class="text-danger fullMessage"
                                >
                                    {{ "InboxIsFullMessage" | localize }}
                                </div>
                            </ng-template>
                        </p-splitter>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
