import {Component, Injector, OnInit} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {
    _definitions_KPMGFileType,
    KPMGAppAttachmentsAppServiceProxy,
    LegalPrivacyInfoDto,
    SettingsServiceProxy,
    VersionInfoDto
} from '@shared/service-proxies/service-proxies';
import {FileDownloadService} from '@shared/utils/file-download.service';

@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent extends AppComponentBase implements OnInit {
    showLoginGuideline = false;
    legalPrivacyInfo = new LegalPrivacyInfoDto();

    versionInfo: VersionInfoDto = new VersionInfoDto();

    constructor(injector: Injector,
                private readonly _settingsService: SettingsServiceProxy,
                private readonly _fileDownloadService: FileDownloadService,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.showLoginGuideline =
            this.appSession.userId === undefined ||
            this.appSession.userId === null;

        this._settingsService.getVersionInformation().subscribe((result) => {
            this.versionInfo = result;
        });

        this._settingsService.getLegalPrivacyInfo()
            .subscribe((result) => {
                this.legalPrivacyInfo = result;
            });

    }

    showDisclaimer(): void {
        abp.event.trigger('app.show.Disclaimer');
    }

    showVersionInfo(): void {
        abp.event.trigger('app.show.VersionInfo');
    }

    downloadLoginFile() {
        this._fileDownloadService.downloadLoginManual().subscribe();
    }

}
